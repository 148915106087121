<nav aria-label="Menu principal">
  <ul class="nav flex-column menu-principal">
    <ng-container *ngIf="perfil === 'ESCOLA'
                          || perfil === 'RAE'
                          || perfil === 'CONSELHO_TUTELAR'
                          || perfil === 'MINISTERIO_PUBLICO'
                          || perfil === 'PROCEMPA_TIMP'"
      [ngTemplateOutlet]="navLink"
      [ngTemplateOutletContext]="{
        icon: faClipboardList,
        title: 'Fichas',
        link: 'fichas'
      }"
    ></ng-container>
    <ng-container *ngIf="perfil === 'ESCOLA'
                  || perfil === 'RAE'
                  || perfil === 'CONSELHO_TUTELAR'
                  || perfil === 'MINISTERIO_PUBLICO'
                  || perfil === 'PROCEMPA_TIMP'"
      [ngTemplateOutlet]="navLink"
      [ngTemplateOutletContext]="{
        icon: faBookOpenReader,
        title: 'Estudantes',
        link: 'alunos'
      }"
    ></ng-container>

    <ng-container *ngIf="perfil === 'GESTOR_MUN' || perfil === 'GESTOR_EST'"
[ngTemplateOutlet]="navLink"
[ngTemplateOutletContext]="{
icon: faGraduationCap,
title: 'Escolas',
link: 'gerenciamento/list-escolas'
}"
></ng-container>

    <ng-container *ngIf="perfil !== 'GESTOR_MUN' && perfil !== 'GESTOR_EST'"
      [ngTemplateOutlet]="navLink"
      [ngTemplateOutletContext]="{
        icon: faChartPie,
        title: 'Relatórios',
        link: 'relatorios/relatorio'
      }"
    ></ng-container>
    </ul>
</nav>

<!-- Expandir/recolher navbar em desktop - implantar depois
<button class="mt-auto">Expandir/recolher</button>

-->

<ng-template #navLink let-icon="icon" let-title="title" let-link="link">
  <li class="nav-item">
    <a
      class="nav-link"
      [routerLink]="link"
      [routerLinkActive]="['active']"
      (click)="hideMenu()"
    >
      <fa-icon [icon]="icon" class="fa-lg"></fa-icon>
      {{ title }}
    </a>
  </li>
</ng-template>
