import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { Order } from '../../types/Order';
import { PaginatedResult } from '../../types/PaginatedResult';
import { TableHeader, TableLocal } from '../../types/TableHeader';

@Component({
  selector: 'app-lista-tabela',
  templateUrl: './lista-tabela.component.html',
  styleUrls: ['./lista-tabela.component.scss']
})
export class ListaTabelaComponent {
  @Input() paginatedResult!: PaginatedResult<any>;
  @Input() headers: TableHeader[] = [];
  @Input() headersLocal: TableLocal[] = [];
  @Input() loading: boolean = true;
  @Input() filter?: boolean = true;

  readonly sortIcons = { default: faSort, asc: faSortUp, desc: faSortDown };

  @Output()
  search: EventEmitter<any> = new EventEmitter();

  loadPage() {
    this.loading = true;
    this.search.emit();
  }

  changeOrder(header: TableHeader) {
    header.ordem = header.ordem === Order.ASC ? Order.DESC : Order.ASC;
    this.paginatedResult.query.atributo = header.atributo;
    this.paginatedResult.query.direction = header.ordem;
    this.search.emit();
  }
}
