import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { UsuarioDto } from 'src/app/core/model/dtos/usuarioDto';
import {
  LocalEscola,
  LocalGeneric,
  LocalRae
} from 'src/app/core/model/localGeneric';
import { PerfilGeneric, Usuario } from 'src/app/core/model/perfilGeneric';
import { Order } from 'src/app/shared/types/Order';
import { TableHeader } from 'src/app/shared/types/TableHeader';

@Component({
  selector: 'app-perfis',
  templateUrl: './perfis.component.html',
  styleUrls: ['./perfis.component.scss']
})
export class PerfisComponent implements OnInit {
  @Input() user?: Usuario;
  @Input() usuario?: UsuarioDto;

  locaisEscola?: Array<LocalEscola> = [];
  locaisRae: Array<LocalRae> = [];
  locaisMinisterio?: Array<LocalGeneric>;
  locaisConselho?: Array<LocalGeneric>;
  locaisCre?: Array<LocalGeneric>;
  @Input() perfis?: Array<PerfilGeneric>;

  perfilEscola = 'ESCOLA';
  perfilRae = 'RAE';
  perfilMinisterio = 'MINISTERIO_PUBLICO';
  perfilTutelar = 'CONSELHO_TUTELAR';
  perfilCre = 'CRE';
  plus = faPlus;

  headers: TableHeader[] = [
    {
      descricao: 'Perfil',
      atributo: 'perfil',
      ordem: Order.ASC,
      ordenavel: true
    },
    {
      descricao: 'Local',
      atributo: 'local',
      ordem: Order.ASC,
      ordenavel: true
    },
    {
      descricao: 'Status',
      atributo: 'status',
      ordem: Order.ASC,
      ordenavel: true
    }
  ];

  constructor(private route: Router) {}

  ngOnInit(): void {
    this.perfis?.forEach((el: PerfilGeneric) => {
      if (el.perfil == this.perfilEscola) {
        this.locaisEscola = this.getPerfisOrder(el.locais);
      }

      if (el.perfil == this.perfilRae) {
        this.locaisRae = this.getPerfisOrder(el.locais);
      }

      if (el.perfil == this.perfilTutelar) {
        this.locaisConselho = this.getPerfisOrder(el.locais);
      }

      if (el.perfil == this.perfilMinisterio) {
        this.locaisMinisterio = this.getPerfisOrder(el.locais);
      }

      if (el.perfil == this.perfilCre) {
        this.locaisCre = this.getPerfisOrder(el.locais);
      }
    });
  }

  getPerfisOrder(arr: Array<LocalGeneric> | undefined): Array<LocalGeneric> {
    return (
      arr?.sort((a, b) => ((a?.id ?? 0) < (b?.id ?? 0) ? -1 : 1)) ?? new Array()
    );
  }

  addAutorizador() {
    this.route.navigate(['perfis-list/add-perfil']);
  }

  getStatus(status: string | undefined): string {
    if (status === 'AUTORIZADO') {
      return 'Autorizado';
    } else if (status === 'AGUARDANDO_AUTORIZACAO') {
      return 'Aguardando Autorização';
    } else if (status === 'DESATIVADO') {
      return 'Desativado';
    }
    return '';
  }
  getOrder(arr: Array<any> | undefined): Array<any> {
    return (
      arr?.sort((a, b) => ((a?.id ?? 0) < (b?.id ?? 0) ? -1 : 1)) ?? new Array()
    );
  }
}
