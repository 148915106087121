import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { PaginatedResult } from '../../types/PaginatedResult';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss']
})
export class PaginacaoComponent implements OnInit {
  @Input() paginatedResult!: PaginatedResult<any>;
  @Input() filter?: boolean = true;

  @Output()
  pageChanged: EventEmitter<any> = new EventEmitter();

  readonly rightArrowIcon = faChevronRight;
  readonly leftArrowIcon = faChevronLeft;

  constructor() {}

  ngOnInit(): void {
    if (!this.paginatedResult) this.paginatedResult = new PaginatedResult();
  }

  loadPage(): void {
    this.pageChanged.emit();
  }

  toPage(eventTarget: EventTarget): void {
    const target = eventTarget as HTMLInputElement;
    this.paginatedResult.query.page = parseInt(target.value);
    this.loadPage();
  }

  nextPage(): void {
    if (
      !this.paginatedResult.last &&
      this.paginatedResult.query.page < this.paginatedResult.totalPages - 1
    ) {
      this.paginatedResult.query.page = this.paginatedResult.query.page + 1;
      this.loadPage();
    }
  }

  previousPage(): void {
    if (!this.paginatedResult.first && this.paginatedResult.query.page > 0) {
      this.paginatedResult.query.page = this.paginatedResult.query.page - 1;
      this.loadPage();
    }
  }

  setPageSize(eventTarget: EventTarget): void {
    const target = eventTarget as HTMLInputElement;
    this.paginatedResult.query.pageSize = parseInt(target.value);
    this.paginatedResult.query.page = 0;
    this.loadPage();
  }

  getPagesList(): number[] {
    let pages: number[] = [];
    for (let index = 0; index <= this.paginatedResult.totalPages - 1; index++) {
      pages.push(index);
    }
    return pages;
  }
}
