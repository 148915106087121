<div class="table-responsive" *ngIf="user?.perfis?.length !== 0; else empty">
    <table class="table mb-0">

        <thead>
            <h4 *ngIf="usuario?.perfisFichas?.length !== 0" class="mt-4">Perfis Ficha</h4>
            <tr>
                <th *ngFor="let header of headers">
                    {{ header.descricao }}
                </th>
            </tr>
          </thead>

          <tbody *ngFor="let perfil of getOrder(usuario?.perfisFichas)" >
             <ng-container *ngIf="perfil.perfil === 'ESCOLA'">
              <tr *ngFor="let local of perfil.locais; let i = index">
                <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Escola</td>
                <td>
                    {{local.escolaNome}}<br>
                    <small class="helper">{{local.cargo}}</small>
                </td>
                <td>
                    {{getStatus(local.status)}}
                    </td>
                </tr>
            </ng-container>

             <ng-container *ngIf="perfil.perfil === 'RAE'">
              <tr *ngFor="let local of perfil.locais; let i = index">
                <td [attr.rowspan]="perfil.locais.length" *ngIf="i === 0">RAE</td>
                <td>
                  <small class="helper"> {{local.politicaPublica}}</small><br>
                  {{local.orgao}}<br>
                  <small class="helper">{{local.cargo}}</small><br>
                </td>
                <td>
                  {{getStatus(local.status)}}
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="perfil.perfil === 'CONSELHO_TUTELAR'">
              <tr *ngFor="let local of perfil.locais; let i = index">
                <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Conselho Tutelar</td>
                <td>
                    {{local.orgao}}<br>
                    <small class="helper">{{local.cargo}}</small><br>
                </td>
                <td>
                  {{getStatus(local.status)}}
                    </td>
                </tr>
            </ng-container>



            <ng-container *ngIf="perfil.perfil === 'MINISTERIO_PUBLICO'">
              <tr *ngFor="let local of perfil.locais; let i = index">
                <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Ministerio publico</td>
                <td>
                  {{local.orgao}}<br>
                  <small class="helper">{{local.cargo}}</small>
                </td>
                <td>
                  {{getStatus(local.status)}}
                    </td>
                </tr>
            </ng-container>
        </tbody>

        <h4 *ngIf="usuario?.perfisRelatorios?.length !== 0" class="mt-4" >Perfis Relatórios</h4>
        <thead>
            <tr>
                <th *ngFor="let header of headers">
                    {{ header.descricao }}
                </th>
            </tr>
          </thead>
          <tbody *ngFor="let perfil of getOrder(usuario?.perfisRelatorios)">
            <ng-container *ngIf="perfil.perfil === 'CRE'">
                <tr *ngFor="let local of perfil.locais; let i = index">
                  <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Coordenadoria Regional de Educação</td>
                  <td>
                      {{local.orgao}}<br>
                  </td>
                  <td>
                    {{getStatus(local.status)}}
                      </td>
                  </tr>
              </ng-container>
              <ng-container *ngIf="perfil.perfil === 'SME'">
                <tr *ngFor="let local of perfil.locais; let i = index">
                  <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Secretaria Municipal de Educação</td>
                  <td>
                      {{local.municipioNome}}<br>
                  </td>
                  <td>
                    {{getStatus(local.status)}}
                      </td>
                  </tr>
              </ng-container>
              <ng-container *ngIf="perfil.perfil === 'SE_RELATORIO'">
                <tr *ngFor="let local of perfil.locais; let i = index">
                  <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Secretaria Estadual de Educação</td>
                  <td>
                      {{local.municipioNome}}<br>
                  </td>
                  <td>
                    {{getStatus(local.status)}}
                      </td>
                  </tr>
              </ng-container>
              <ng-container *ngIf="perfil.perfil === 'MP_RELATORIO'">
                <tr *ngFor="let local of perfil.locais; let i = index">
                  <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Ministério Público (Relatórios)</td>
                  <td>
                      {{local.municipioNome}}<br>
                  </td>
                  <td>
                    {{getStatus(local.status)}}
                      </td>
                  </tr>
              </ng-container>
          </tbody>

          <h4 *ngIf="usuario?.perfisAutorizacao?.length !== 0" class="mt-4">Perfis Autorizador</h4>
          <thead>
              <tr>
                  <th *ngFor="let header of headers">
                      {{ header.descricao }}
                  </th>
              </tr>
            </thead>
            <tbody *ngFor="let perfil of getOrder(usuario?.perfisAutorizacao)">
              <ng-container *ngIf="perfil.perfil === 'AUTORIZADOR_MUN'">
                  <tr *ngFor="let local of perfil.locais; let i = index">
                    <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Autorizador Municipal</td>
                    <td>
                        {{local.municipioNome}}<br>
                    </td>
                    <td>
                      {{getStatus(local.status)}}
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="perfil.perfil === 'AUTORIZADOR_EST'">
                  <tr *ngFor="let local of perfil.locais; let i = index">
                    <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Autorizador Seduc</td>
                    <td>
                       <!-- Rio Grande do Sul -->
                    </td>
                    <td>
                      {{getStatus(local.status)}}
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="perfil.perfil === 'AUTORIZADOR_MP'">
                    <tr *ngFor="let local of perfil.locais; let i = index">
                      <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Autorizador Ministério Público</td>
                      <td>
                        <!-- Rio Grande do Sul -->
                      </td>
                      <td>
                        {{getStatus(local.status)}}
                          </td>
                      </tr>
                  </ng-container>

                  <ng-container *ngIf="perfil.perfil === 'AUTORIZADOR_IF'">
                    <tr *ngFor="let local of perfil.locais; let i = index">
                      <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Autorizador Federal</td>
                      <td>

                      </td>
                      <td>
                        {{getStatus(local.status)}}
                          </td>
                      </tr>
                  </ng-container>
            </tbody>

            <h4 *ngIf="usuario?.perfisGestao?.length !== 0" class="mt-4">Perfis Gerenciamento</h4>
            <thead>
                <tr>
                    <th *ngFor="let header of headers">
                        {{ header.descricao }}
                    </th>
                </tr>
              </thead>
              <tbody *ngFor="let perfil of usuario?.perfisGestao">
                <ng-container *ngIf="perfil.perfil === 'GESTOR_MUN'">
                    <tr *ngFor="let local of perfil.locais; let i = index">
                      <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Gestor Municipal</td>
                      <td>
                          {{local.municipioNome}}<br>
                      </td>
                      <td>
                        {{getStatus(local.status)}}
                          </td>
                      </tr>
                  </ng-container>
                  <ng-container *ngIf="perfil.perfil === 'GESTOR_EST'">
                    <tr *ngFor="let local of perfil.locais; let i = index">
                      <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Gestor Estadual</td>
                      <td>
                         <!-- Rio Grande do Sul -->
                      </td>
                      <td>
                        {{getStatus(local.status)}}
                          </td>
                      </tr>
                  </ng-container>
                  <ng-container *ngIf="perfil.perfil === 'GESTOR_MP'">
                      <tr *ngFor="let local of perfil.locais; let i = index">
                        <td [attr.rowspan]="perfil.locais?.length" *ngIf="i === 0">Gestor Ministério Público</td>
                        <td>
                          <!-- Rio Grande do Sul -->
                        </td>
                        <td>
                          {{getStatus(local.status)}}
                            </td>
                        </tr>
                    </ng-container>
              </tbody>

    </table>
</div>

<ng-template #empty>
  <td colspan="5">
    <div class="sem-resultados">
      <p class="lead">Você não possui perfil cadastrado!</p>
      <img class="img-responsive" src="../assets/images/sem-resultados.png"
            alt="Ilustração de estudante cercado por documentos e pontos de interrogação,
        coçando a cabeça em pose de dúvida">
      <p>Cadastre um perfil.</p>
    </div>
  </td>
</ng-template>

<div class="botoes mt-4">
    <button class="br-button is-primary" (click)="addAutorizador()">
        <fa-icon [icon]="plus" aria-hidden="true"></fa-icon>
        Adicionar perfil
    </button>
</div>
