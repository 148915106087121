import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Municipio } from 'src/app/alunos/model/municipio';
import { PaginatedResult } from 'src/app/shared/types/PaginatedResult';
import { PaginationQuery } from 'src/app/shared/types/PaginationQuery';
import { environment } from 'src/environments/environment';
import { FichaListItem } from '../model/Ficha';
import {
  Arquivamento,
  ArquivamentoTipo
} from '../model/arquivamento/arquivamento';
import { Contato } from '../model/contato';
import { DiagnosticoEditDTO } from '../model/dto/diagnosticoDto';
import { Encaminhamento } from '../model/dto/encaminhamento';
import { FichaAddMedidasProtetivasDTO } from '../model/dto/medidaProtetivaDto';
import { Expediente } from '../model/expediente';
import { Visita } from '../model/visita';
import { Pais } from '../model/pais';
import { Escola } from '../model/escola';
import { HandlerErrorService } from 'src/app/shared/services/handler-error.service';
import { InformacaoRetorno } from '../model/informacaoRetorno';
import { PromotoriaDto } from 'src/app/core/model/dtos/promotoriaDto';

@Injectable({
  providedIn: 'root'
})
export class FichaService {
  arr: Array<any> = [];
  backendUrl = environment.BASE_URL;

  perfil = new BehaviorSubject('');
  local = new BehaviorSubject(false);
  // actions = new BehaviorSubject(false);
  perfilAtivo = new BehaviorSubject(undefined);
  refleshDiag = new BehaviorSubject([]);

  private _fichaArquivada = new BehaviorSubject<boolean>(true);
  fichaArquivada = this._fichaArquivada.asObservable();

  setRefleshDiag(msg: any): void {
    this.refleshDiag.next(msg);
  }

  getRefleshDiag(): Observable<any> {
    return this.refleshDiag.asObservable();
  }

  setPerfil(msg: any): void {
    this.perfil.next(msg);
  }

  getPerfil(): Observable<any> {
    return this.perfil.asObservable();
  }

  setLocal(msg: any): void {
    this.local.next(msg);
  }

  getLocal(): Observable<any> {
    return this.local.asObservable();
  }

  setPerfilAtivo(msg: any): void {
    this.perfilAtivo.next(msg);
  }

  getPerfilAtivo(): Observable<any> {
    return this.perfilAtivo.asObservable();
  }

  // setAction(msg: any): void {
  //   this.actions.next(msg);
  // }

  // getAction(): Observable<any> {
  //   return this.actions.asObservable();
  // }

  setFichaArquivada(msg: any): void {
    this._fichaArquivada.next(msg);
  }

  constructor(private http: HttpClient, private toastr: HandlerErrorService) {}

  findEscolaByNome(data: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/nome?=${data}`);
  }

  diagnosticoFindAll(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/diagnostico/all`);
  }

  public create(dto: any): Observable<any> {
    const url = `${this.backendUrl}/ficha`;
    return this.http.post<any>(url, dto).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.toastr.badRequest(err.error.message || err.error.situacaoAluno);
        }
        return throwError(() => err);
      })
    );
  }

  public getSituacaoAluno(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/situacao-aluno/all`);
  }

  public findFichasByAluno(id: any): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/ficha/aluno/${id}`);
  }

  public findFichaById(id: any): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}`);
  }

  public createFichaDiagnostico(
    id: number,
    diagnosticos: DiagnosticoEditDTO
  ): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/diagnosticos`;
    return this.http.post<any>(url, diagnosticos);
  }

  public findByDiagnosticos(id: number): Observable<Array<any>> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}/diagnosticos`);
  }

  validatorInputs(form: FormGroup) {
    Object.keys(form.controls).forEach((field: any) => {
      const control = form?.get(field);
      control?.markAsTouched();
    });
  }

  createVisita(id: number, visita: Visita): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/visita`;
    return this.http.post<any>(url, visita).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  createContato(id: number, contato: Contato): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/contato`;
    return this.http.post<any>(url, contato).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  informaRetorno(
    id: number,
    informacaoRetorno: InformacaoRetorno
  ): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/informa-retorno`;
    return this.http.post<any>(url, informacaoRetorno).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  getMedidas(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/medidas-protetivas`);
  }

  createMedidasProtetiva(
    id: string,
    medidas: FichaAddMedidasProtetivasDTO
  ): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/medidas-protetivas`;
    return this.http.post<any>(url, medidas).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  createEncaminhamento(
    id: number,
    encaminhamentoNewDTO: Encaminhamento
  ): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/encaminhamento`;
    return this.http.post<any>(url, encaminhamentoNewDTO).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 400) {
          this.toastr.badRequest(err.error.message);
        }
        return throwError(() => err);
      })
    );
  }

  arquivarFicha(id: number, arquivamento: Arquivamento): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/arquivamento`;
    return this.http.post<any>(url, arquivamento).pipe(
      catchError((err) => {
        if (err.status == 400) {
          this.toastr.badRequest(err.error.message);
        }
        return throwError(() => err);
      })
    );
  }

  getMunicipioAll(): Observable<Array<Municipio>> {
    return this.http.get<any>(`${this.backendUrl}/municipio/all`);
  }

  getMunicipio(nome: string): Observable<Array<Municipio>> {
    return this.http.get<any>(`${this.backendUrl}/municipio/nome/${nome}`);
  }

  getEscolas(id: number): Observable<Array<Escola>> {
    return this.http.get<Escola[]>(`${this.backendUrl}/escola/municipio/${id}`);
  }

  getEscolasSearch(id: number, termo: string): Observable<Array<Escola>> {
    return this.http.get<Escola[]>(
      `${this.backendUrl}/escola/municipio/${id}/termo/${termo}`
    );
  }

  // getEscolasTeste(id: number, termo: string): Observable<Array<any>> {
  //   return this.http.get<any>(`${this.backendUrl}/escola/municipio/${id}`).pipe(filter(termo: any));
  // }

  getMunicipioByNome(nome: any): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/municipio/nome/${nome}`);
  }

  getHistoricoFicha(id: string): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}/historico`);
  }

  createPlanoTrabalho(id: number, plano: FormData): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/plano-de-trabalho`;
    return this.http.post<any>(url, plano).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  createEstudoCaso(id: number, plano: FormData): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/estudo-de-caso`;
    return this.http.post<any>(url, plano).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  createExpediente(id: number, expediente: Expediente): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/expediente-extrajudicial`;
    return this.http.post<any>(url, expediente).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  searchFichas(
    query: PaginationQuery
  ): Observable<PaginatedResult<FichaListItem>> {
    let url = `${this.backendUrl}/ficha/pesquisa?page=${query.page}&size=${query.pageSize}`;
    if (query.search) url += `&search=${query.search}`;
    if (query.direction) url += `&direction=${query.direction}`;
    if (query.atributo) url += `&atributo=${query.atributo}`;
    if (query.filters) {
      if (query.filters['exibir_arquivadas'])
        url += `&exibir_arquivadas=${query.filters['exibir_arquivadas']}`;
      if (query.filters['outros_locais'])
        url += `&outros_locais=${query.filters['outros_locais']}`;
    }
    return this.http.get<PaginatedResult<FichaListItem>>(url).pipe(
      map((res) => {
        return this.formatSearchResult(res);
      })
    );
  }

  formatSearchResult(res: any): PaginatedResult<FichaListItem> {
    const result = new PaginatedResult<FichaListItem>();
    result.query.page = res.number;
    result.query.pageSize = res.size;
    result.collectionSize = res.totalElements;
    for (let fichaResponse of res.content) {
      let ficha: FichaListItem = new FichaListItem(
        fichaResponse.id,
        fichaResponse.id.toString(), //usar o numero da ficha quando implementado no backend
        fichaResponse.aluno,
        fichaResponse.createdOn,
        fichaResponse.updatedOn,
        fichaResponse.status,
        fichaResponse.localizacaoAtual,
        fichaResponse.urgente,
        fichaResponse.situacaoDeRisco
      );
      result.data.push(ficha);
    }
    result.totalPages = res.totalPages;
    result.first = res.first;
    result.last = res.last;
    return result;
  }

  getPaises(): Observable<Array<Pais>> {
    return this.http.get<any>(`${this.backendUrl}/pais/all`);
  }

  public findFichaByIdDetalhe(link: any): Observable<any> {
    // if (tipo == 'VISITA_REALIZADA') {
    //   return this.http.get<any>(`${this.backendUrl}/ficha${link}`);
    // }
    return this.http.get<any>(`${this.backendUrl}${link}`);
  }

  getAllTypeArquivamento(): Observable<Array<ArquivamentoTipo>> {
    return this.http.get<any>(`${this.backendUrl}/arquivamento-tipo/all`);
  }

  getAllArquivamento(): Observable<Array<ArquivamentoTipo>> {
    return this.http.get<any>(`${this.backendUrl}/arquivamento-tipo`);
  }

  getInfoFicha(id: string): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}/info`);
  }

  getControleFluxo(id: any): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}/permissao-fluxo`);
  }

  getConselhoPorMunicipio(id: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/conselho-tutelar/municipio/${id}`
    );
  }

  getHistoricoDiagnostico(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/historico/diagnosticos/${id}/detalhes`
    );
  }

  actionCt(id: number, acao: any): Observable<any> {
    const url = `${this.backendUrl}/ficha/${id}/acao-ct`;
    return this.http.post<any>(url, acao).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  getFichaImprimir(id: any): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/ficha/${id}/imprimir`);
  }

  searchFichasFilter(
    query: PaginationQuery,
    queryFilter: string
  ): Observable<PaginatedResult<FichaListItem>> {
    const qs = new HttpParams({ fromString: queryFilter });
    let url = `${this.backendUrl}/ficha/pesquisa-avancada?page=${query.page}&size=${query.pageSize}`;
    if (query.search) url += `&search=${query.search}`;
    if (query.direction) url += `&direction=${query.direction}`;
    if (query.atributo) url += `&atributo=${query.atributo}`;
    if (query.filters) {
      if (query.filters['exibir_arquivadas'])
        url += `&exibir_arquivadas=${query.filters['exibir_arquivadas']}`;
      if (query.filters['outros_locais'])
        url += `&outros_locais=${query.filters['outros_locais']}`;
    }
    if (queryFilter) {
      url += `&${qs}`;
    }
    return this.http.get<PaginatedResult<FichaListItem>>(url).pipe(
      map((res) => {
        return this.formatSearchResult(res);
      })
    );
  }

  getEtnias(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/etnia`);
  }

  getGeneros(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/genero`);
  }

  getTurnos(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/turno`);
  }

  getAnoCurso(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/ano-curso`);
  }

  getTipoEscola(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.backendUrl}/tipo-escola`);
  }

  public getRae(id: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/rae/municipio/${id}`);
  }

  public getMicros(id: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/conselho-tutelar/municipio/${id}`
    );
  }

  public getPromotorias(): Observable<Array<PromotoriaDto>> {
    return this.http.get<Array<PromotoriaDto>>(
      `${this.backendUrl}/promotoria/all`
    );
  }
}
